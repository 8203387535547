.anchor {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1;
  text-decoration: none;
  color: #333;
  user-select: none;

  &:hover {
    background-color: #f2f2f2;
  }
}

.icon {
  font-size: 20px;
  color: #636f82;
}

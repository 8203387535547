.wrapper {
  width: 26px;
  height: 20px;
  cursor: pointer;
  user-select: none;
}

.burger {
  display: flex;
  flex-direction: column;
  gap: 7px;

  span {
    display: block;
    height: 2px;
    border-radius: 2px;

    .dark & {
      background-color: #333;
    }

    .light & {
      background-color: #fff;
    }
  }
}

.cross {
  width: 20px;
  height: 20px;

  span {
    position: relative;
    display: block;
    width: 28px;
    height: 2px;
    margin-left: 3px;
    border-radius: 2px;

    .dark & {
      background-color: #333;
    }

    .light & {
      background-color: #fff;
    }

    &:nth-child(1) {
      top: 9px;
      left: -4px;
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      top: 7px;
      left: -4px;
      transform: rotate(-45deg);
    }

    &:nth-child(3) {
      display: none;
    }
  }
}

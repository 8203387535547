@import '../../../../styles/shared';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.menu {
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  min-width: 263px;
  z-index: 1;

  @include mq($from: tablet) {
    min-width: 300px;
  }
}

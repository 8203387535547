.wrapper {
  position: relative;
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 12px 0 rgba(2, 16, 35, 0.1);

  &::before {
    position: absolute;
    top: -7px;
    right: 43px;
    content: '';
    width: 15px;
    height: 15px;
    background-color: #fff;
    transform: rotate(45deg);
  }
}

.header {
  padding: 16px;
  line-height: 1;
}

.body {
  padding: 8px 0;
  border-top: 1px solid #dee0e4;
  border-bottom: 1px solid #dee0e4;
}

.footer {
  padding: 8px 0;
}

.name {
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.email {
  font-size: 14px;
  color: #636f82;
}

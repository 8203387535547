@import '../../../../styles/shared';

.wrapper {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  background: var(--hub-mobile-nav-background-color);

  @include mq($from: desktop) {
    display: none;
  }
}

.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: var(--hub-mobile-nav-height);
  transition: min-height 0.2s;
}

.bodyRight {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #fff;
}

.menu {
  position: absolute;
  top: var(--hub-mobile-nav-height);
  right: 0;
  left: 0;
  transition: top 0.2s;
}

.brandLogo {
  display: block;
  height: var(--hub-mobile-nav-logo-height);
}

:global(.sticky) {
  .body {
    min-height: var(--hub-mobile-nav-sticky-height);
  }

  .menu {
    top: var(--hub-mobile-nav-sticky-height);
  }
}

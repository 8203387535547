.wrapper {
  display: flex;
  align-items: center;
  gap: 7px;
  user-select: none;
}

.picture {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.name {
  @extend .picture;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #333;
  background-color: #fff;
}

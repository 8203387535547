.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  font-size: 20px;
  color: #fff;
  background: var(--color-scheme-accent-main);
  border-radius: 50%;
  user-select: none;
  cursor: pointer;
}
